<template>
    <div class="row mb-2">
        <div class="col-lg-3">
            <b>{{ label }}</b>
        </div>
        <div class="col-1 d-none d-lg-flex justify-content-end">:</div>
        <div class="col-lg-4">
            {{ value }}
        </div>
    </div>
</template>

<script>

export default {
    props: ["label", "value"]
}
</script>