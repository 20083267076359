<template>
  <div class="animated fadeIn">
    <b-card>
      <template v-slot:header>
        <h5>
          Arsip FPP
        </h5>
      </template>
      <div>
        <b-form @submit.prevent="handleSearch">
          <div class="row">
            <div class="col-lg-4 col-xl-3">
              <b-form-group label="Tanggal">
                <date-range-picker class="w-100" v-model="dateRange" ref="picker"
                  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                  :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true" :showDropdowns="true"
                  :autoApply="true" @select="handleSelectDate">
                </date-range-picker>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-xl-3">
              <b-form-group label="Kota/Kabupaten">
                <div class="form-group">
                  <multiselect @select="setQuery('kabupaten_kota_id', $event)" class="w-100"
                    placeholder="Pilih Kabupaten/Kota" label="text" track-by="id" v-model="selectKabKot"
                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="false"
                    :options="filtered_cities"></multiselect>
                </div>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-xl-3">
              <b-form-group label="Cabang">
                <div class="form-group">
                  <multiselect @select="setQuery('id_cabang_multiple', $event)" class="w-100" placeholder="Pilih Cabang"
                    label="text" track-by="id" v-model="selectedCabang" :show-labels="false" :close-on-select="false"
                    @remove="removeItemCabang" :clear-on-select="false" :multiple="true" :options="cabangLists">
                  </multiselect>
                </div>
              </b-form-group>

            </div>
            <div class="col-lg-4 col-xl-3">
              <b-form-group label="Status">
                <div class="form-group">
                  <multiselect class="w-100" placeholder="Pilih Status" label="text" track-by="id"
                    v-model="selectedStatus" :show-labels="false" :close-on-select="true" :clear-on-select="false"
                    @select="setQuery('flag_status', $event)" :multiple="false" :options="option_status_fpp">
                  </multiselect>
                </div>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-xl-3">
              <b-form-group label="Kategori FPP">
                <div class="form-group">
                  <multiselect @select="setQuery('id_kategori_jenis_fpp', $event)" class="w-100"
                    placeholder="Kategori Pengajuan" label="name" track-by="id" v-model="selectedJenisPengajuan"
                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="false"
                    :options="kategori_jenis"></multiselect>
                </div>
              </b-form-group>
            </div>
            <div class="col-lg-4 col-xl-3">
              <b-form-group label="Pengajuan">
                <div class="form-group">
                  <multiselect @select="setQuery('id_kategori_sub_fpp', $event)" class="w-100"
                    placeholder="Pilih Pengajuan" label="name" track-by="id" v-model="selectedPengajuan"
                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="false"
                    :options="kategori_sub"></multiselect>
                </div>
              </b-form-group>

            </div>
            <div class="col-lg-4 col-xl-3">
              <b-form-group label="No Pengajuan">
                <b-form-input @change="setQuery('im_number', $event)" placeholder="Cari No Pengajuan"
                  v-model="queryParam.im_number"></b-form-input>
              </b-form-group>
            </div>
            <div class="col align-items-center mb-1 d-flex justify-content-end">
              <b-button type="submit" variant="pgiBtn">
                Cari <i class="fa fa-search"></i>
              </b-button>
              <b-button @click="onResetQuery" variant="dark" type="button" class="ml-1">
                <i class="fa fa-refresh"></i> Reset
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
      <TabelArsip :queryParam="queryParam" :hasFilter="hasFilter" />
    </b-card>
  </div>
</template>

<script>
import TabelArsip from "../../components/fpp/arsipfpp/tableArsip.vue";
import { mapState } from "vuex";
import DateRangePicker from 'vue2-daterange-picker'
export default {
  components: {
    TabelArsip,
    DateRangePicker
  },
  data() {
    return {
      role: localStorage.getItem("role"),
      queryParam: {
        page: this.$route.query.page || 1,
        per_page: 10,
      },
      optionsFilter: [
        {
          value: null,
          disabled: true,
          text: "filter berdasarkan",
        },
        {
          value: "kabkot",
          text: "Kabupaten/Kota",
        },
        {
          value: "id_cabang",
          text: "Cabang",
        },
        {
          value: "jenis-kategori",
          text: "Jenis Pengajuan",
        },
        {
          value: "kategori",
          text: "Kategori Pengajuan",
        },
        {
          value: "sub-kategori",
          text: "Pengajuan",
        },
        {
          value: "flag_status",
          text: "Status",
        },
      ],
      selectKabKot: null,
      selectedCabang: [],
      selectedPengajuan: null,
      selectedJenisPengajuan: null,
      selectedStatus: null,
      dateRange: { startDate: null, endDate: null },
      hasFilter: false
    }
  },
  methods: {
    handleSelectDate(value) {
      const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
      const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
      this.queryParam.startDate = startDate
      this.queryParam.endDate = endDate
    },
    setQuery(type, value) {
      let newQuery = this.queryParam;
      if (type === 'im_number') {
        newQuery = {
          ...this.queryParam,
          [type]: value
        }
      }
      else {
        newQuery = {
          ...this.queryParam,
          [type]: value.id,
        }

      }
      this.queryParam = newQuery
    },
    removeItemCabang() {
      this.queryParam = {
        ...this.queryParam,
        page: 1
      }
    },
    async handleSearch() {
      const cabangIds = this.selectedCabang.map(i => i.id)
      this.queryParam = {
        ...this.queryParam,
        id_cabang_multiple: cabangIds.length ? cabangIds : undefined
      }

      await this.$store.dispatch("getHistoryFpp", this.queryParam)
      this.hasFilter = true
    },
    onResetQuery() {
      this.queryParam = {
        page: 1,
        per_page: 10
      }
      this.selectKabKot = null
      this.selectedCabang = []
      this.selectedPengajuan = null
      this.selectedJenisPengajuan = null
      this.selectedKategoriPengajuan = null
      this.selectedStatus = null
      this.dateRange = { startDate: null, endDate: null }
      this.hasFilter = false
    }
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
    filtered_cities() {
      return this.$store.state.wilayah.cities
    },
    kategori_sub() {
      return this.$store.state.fpp.kategori_sub
    },
    kategori_jenis() {
      return this.$store.state.fpp.kategori_jenis
    },
    kategori_im() {
      return this.$store.state.fpp.kategori_im
    },
    option_status_fpp() {
      return this.$store.state.fpp.option_status_fpp
    },
    cabangLists() {
      return this.$store.state.global_data.cabangLists
    },
    user_maintenance() {
      return this.$store.state.fpp.user_maintenance
    },
  },
  mounted() {
    this.$store.dispatch("getCity")
    this.$store.dispatch("getCategoryIM")
    this.$store.dispatch("getCategoryJenis")
    this.$store.dispatch("getCategorySub")
    this.$store.dispatch("getOptionStatusFpp")
    this.$store.dispatch("getCabang")
    this.$store.dispatch("getUserMaintenance")
  }
}
</script>