<template>
    <div>

        <div class="row">
            <div class="col-lg-6">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Keranjang Material/Bahan</th>
                            <th>Jumlah</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-top" v-for="barang in memo.barang" :key="barang.id">
                            <td>
                                {{ barang.id_barang }}
                            </td>
                            <td>
                                {{ barang.tipe }}
                            </td>
                            <td>{{ barang.quantity }}</td>
                            <td>
                                <b-button @click="deleteItemKeranjang(barang)" size="sm" variant="danger">
                                    <i class="fa fa-trash"></i>
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end">
                    <b-button size="sm" @click="handleGetMaterial" variant="pgiBtn">Tambah Material</b-button>
                </div>
            </div>
            <div class="col-lg-6">
                <template v-if="barangList">
                    <b-form @submit.prevent="handleSearchBarang" class="my-2">
                        <b-input-group class="mt-3">
                            <b-form-input v-model="keyword" placeholder="Cari barang..."></b-form-input>
                            <b-input-group-append>
                                <b-button type="submit" variant="pgiBtn">Cari</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form>
                    <table class="table table-sm table-borderless">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ID</th>
                                <th>Nama Barang</th>
                                <th>Jumlah</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-top" v-for="barang in barangList.data" :key="barang.id">
                                <td>{{ barang.id_tipe }}</td>
                                <td>
                                    <b-button @click="handleTambahItemKeranjang(barang)" size="sm" variant="pgiBtn">
                                        <i class="fa fa-plus"></i>
                                    </b-button>
                                </td>
                                <td>{{ barang.barang_tipe.tipe }}</td>
                                <td>
                                    {{ barang.jumlah_stok }}
                                    {{ barang.barang_tipe.satuan }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <b-button @click="closeModal" variant="dark">Selesai</b-button>
        </div>
    </div>
</template>

<script>
import axiosClient from '../../../config/api'

export default {
    props: ['memo'],
    data() {
        return {
            showBarang: false,
            keyword: null
        }
    },
    computed: {
        barangList() {
            return this.$store.state.fpp.barangList
        },
        query_params() {
            return this.$store.state.fpp.query_params
        }
    },
    methods: {
        handleSearchBarang() {
            this.$store.dispatch("getStockBarangFpp", {
                tipe: this.keyword
            })
        },
        async deleteItemKeranjang(data) {
            this.memo.barang = this.memo.barang.filter(item => item.id !== data.id)
            const formDelete = {
                id_memo: [this.memo.id],
                id_barang: [data.id_barang],
                cabang_kode: [this.memo.cabang.kode]
            }

            try {
                const res = await axiosClient({
                    url: 'internal-memo/memo/deleteBarangMaintenance',
                    method: 'POST',
                    data: formDelete
                })
                if (res.status === 200) {
                    this.$helper.toastSucc(this, "Berhasil menghapus item keranjang")
                }
            } catch (error) {
                console.log(error)
            }

        },
        async handleTambahItemKeranjang(data) {
            const idSelected = data.id_tipe

            const dataForCreate = {
                "id_memo": [this.memo.id],
                "id_barang": [idSelected],
                "quantity": [1],
                "cabang_kode": ["0999"],
                // "cabang_kode": [this.memo.cabang.kode]
            }

            await axiosClient({
                url: 'internal-memo/memo/createBarangMaintenance',
                method: 'POST',
                data: dataForCreate
            })
            // CARI MEMO.BARANG
            const memoBarangSelected = this.memo.barang.find(i => i.id === idSelected)
            if (memoBarangSelected) {
                // JIKA ADA UPDATE
                this.memo.barang.map(item => {
                    if (item.id === idSelected) {
                        item.quantity = parseInt(item.quantity) + 1
                    }
                    return item
                })
            } else {
                // JIKA TIDAK APPEND
                const newDataBarang = {
                    id: idSelected,
                    id_barang: idSelected,
                    quantity: 1,
                    tipe: data.barang_tipe.tipe,
                    satuan: data.barang_tipe.satuan,
                }
                this.memo.barang.push(newDataBarang)
            }

        },
        closeModal() {
            this.$bvModal.hide('m-edit-material')
            this.showBarang = false;
        },
        async handleGetMaterial() {
            this.showBarang = true;
            this.$store.dispatch("getStockBarangFpp")
        }
    }
}

</script>