<template>
    <div>
        <!-- <pre>
            {{ memo }}
        </pre> -->
        <ItemKeyVal label="No. Pengajuan" :value="memo.im_number" />
        <template v-if="memo.cabang">
            <ItemKeyVal label="Cabang" :value="memo.cabang.name" />
        </template>
        <ItemKeyVal label="Divisi" :value="memo.devisi.nm_Divisi" />
        <template v-if="memo.kategori_jenis">
            <ItemKeyVal label="Kategori" :value="memo.kategori_jenis.name" />
        </template>
        <template v-if="memo.kategori_sub">
            <ItemKeyVal label="Pengajuan" :value="memo.kategori_sub.name" />
        </template>
        <ItemKeyVal label="Jumlah" :value="memo.qty" />
        <ItemKeyVal label="Catatan" :value="memo.catatan" />
        <ItemKeyVal label="Estimasi Pengerjaan" :value="memo.kategori_sub ? memo.kategori_sub.sla + ' hari' : '-'" />
        <!-- HIDDEN DULU -->
        <div class="row mb-2 d-none">
            <div class="col-lg-3">
                <b>Sisa Waktu Pengerjaan</b>
            </div>
            <div class="col-1 d-none d-lg-flex justify-content-end">:</div>
            <div class="col-lg-4">
                <div v-if="isEnded" class="text-danger"><b>Kadaluwarsa</b></div>
                <div v-else class="pgi-box-timers">
                    <div class="timer-box">
                        <div class="timer-item">{{ days }}</div>
                        <span class="timer-satuan">Hari</span>
                    </div>
                    <div class="timer-box">
                        <div class="timer-item">{{ hours }}</div>
                        <span class="timer-satuan">Jam</span>
                    </div>
                    <div class="timer-box">
                        <div class="timer-item">{{ minutes }}</div>
                        <span class="timer-satuan">Menit</span>
                    </div>
                    <div class="timer-box">
                        <div class="timer-item">{{ seconds }}</div>
                        <span class="timer-satuan">Detik</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <hr> -->
        <div>
            <div class="mb-3">
                <b>File Pendukung</b>
            </div>
            <div class="row">
                <div class="col-md-3 col-xl-3" v-for="file in memo.memo_file" :key="file.id">
                    <template v-if="file.flag.toLowerCase() === 'pdf'">PDF</template>
                    <template v-if="file.flag.toLowerCase() === 'foto'">
                        <expandable-image :src="file.path" />
                    </template>
                    <template v-if="file.flag.toLowerCase() === 'video'">
                        <div class="video-box">
                            <video controls muted autoplay playsinline>
                                <source :src="file.path" type="video/mp4">
                                <source :src="file.path" type="video/ogg">
                                Your browser does not support the video tag.
                            </video>

                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="memo.barang">
            <hr>
            <div class="mb-2">
                <b>Daftar Material/Bahan</b>
                <div class="row">
                    <div class="col-lg-6">
                        <b-table bordered hover show-empty small class="" :fields="fieldsMaterial" :items="memo.barang">
                            <template #empty="scope"> Data tidak ditemukan </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="memo.user_maintenance && memo.user_maintenance.length">
            <hr>
            <div class="mb-2">
                <b>Petugas Pemeliharaan</b>
            </div>
            <div>
                <div class="row">
                    <div class="col-lg-6">
                        <div v-for="item in memo.user_maintenance" :key="item.id" class="item-petugas">
                            <div class="foto-petugas-img p-1">
                                <img @error="handleImageError" :src="item.foto" alt="">
                            </div>
                            <div class="info-petugas">
                                <div class="name">
                                    {{ item.nama }}
                                </div>
                                <div class="hp">{{ item.no_telp }}</div>
                                <div v-if="memo.memo_maintenance.length" class="hp">
                                    {{ memo.memo_maintenance[0].date | moment("D MMM Y") }}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div>
            <div class="mb-2">
                <b>Riwayat Pengajuan</b>
            </div>
            <div>
                <b-table bordered hover show-empty small class="mb-3 my-custom-table table-history" :fields="fieldsHistory"
                    :items="memo.list_history_memo">
                    <template #empty="scope"> Data tidak ditemukan </template>
                    <template v-slot:cell(tanggal)="row">
                        {{ row.item.created_at | moment("D MMM Y, HH:mm") }}
                    </template>
                    <template v-slot:cell(status)="row">
                        <div v-if="row.item.status == 0">
                            <span class="badge badge-warning">Pengajuan Ditinjau Ulang</span>
                        </div>

                        <div v-if="row.item.status == 1">
                            <span class="badge badge-primary">Pengajuan Disetujui</span>
                        </div>

                        <div v-if="row.item.status == 2">
                            <span class="badge badge-primary">Pengajuan Disetujui</span>
                        </div>

                        <div v-else-if="row.item.status == 3">
                            <span class="badge badge-info text-white">Pengajuan Diproses</span>
                        </div>

                        <div v-else-if="row.item.status == 4">
                            <span class="badge badge-success text-white">Pengajuan Selesai</span>
                        </div>

                        <div v-else-if="row.item.status == 8">
                            <span class="badge badge-secondary">Pengajuan Dibatalkan</span>
                        </div>

                        <div v-else-if="row.item.status == 10">
                            <span class="badge badge-dark text-white">Pengajuan Ditolak</span>
                        </div>

                        <div v-else-if="row.item.status == 11">
                            <span class="badge badge-danger text-white">Pengajuan Dijadwalkan Ulang</span>
                        </div>

                        <div v-else-if="row.item.status == 12">
                            <span class="badge badge-success">Petugas Hadir</span>
                        </div>

                        <div v-else-if="row.item.status == 13">
                            <span class="badge badge-success">Menunggu Penilaian</span>
                        </div>
                    </template>

                </b-table>
            </div>
        </div>
        <hr>
        <div>
            <div v-for="ulasan in memo.memo_rating" :key="ulasan.id">
                <div class="flex">
                    <i v-for="r in Array.from(Array(ulasan.rating).keys())" :key="r"
                        class="fa fa-star icon-star-rating-fill"></i>
                    <i v-for="r in Array.from(Array(5 - ulasan.rating).keys())" :key="r"
                        class="fa fa-star icon-star-rating"></i>
                </div>
                <div>
                    {{ ulasan.keterangan }}
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <b-button @click="$bvModal.hide('m-detail-arsip-fpp')" variant="dark">Tutup</b-button>
        </div>
    </div>
</template>
<script>
import FlipCountdown from 'vue2-flip-countdown'
import ItemKeyVal from './itemKeyVal.vue';
export default {
    props: ["memo"],
    data() {
        return {
            days: null,
            hours: null,
            minutes: null,
            seconds: null,
            isEnded: null,
            endDate: null,
            fieldsHistory: [
                {
                    key: "tanggal",
                    label: "Tanggal",
                    thClass: "nameOfTheClassThTanggal",
                    tdClass: "nameOfTheClassTd",
                },
                {
                    key: "status",
                    label: "Status",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                },
                {
                    key: "waktu_proses",
                    label: "Waktu Proses",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                },
                {
                    key: "keterangan",
                    label: "Keterangan",
                    thClass: "nameOfTheClassTh",
                },
                {
                    key: "catatan",
                    label: "Catatan",
                    thClass: "nameOfTheClassTh",
                },
            ],
            fieldsMaterial: [
                {
                    key: "tipe",
                    label: "Material/Bahan",
                    thClass: "nameOfTheClassTh",
                },
                {
                    key: "quantity",
                    label: "Jumlah",
                    thClass: "nameOfTheClassThQuantity",
                    tdClass: "nameOfTheClassTd",
                },
            ],
            userId: localStorage.getItem("id"),
            role: localStorage.getItem("role"),
            katProses: JSON.parse(localStorage.getItem("k_proses"))[0],
        }
    },
    components: { ItemKeyVal, FlipCountdown },
    methods: {
        handleImageError(e) {
            e.target.src = "/img/brand/user.png"
        },
        updateRemaining(distance) {
            this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
        },
        tick() {
            const currentTime = new Date()
            if (this.memo.flag != 4 && this.memo.flag != 8 && this.memo.flag != 10) {
                const distance = Math.max(new Date(new Date(this.memo.memo_maintenance[0].date).getTime() + this.memo.kategori_sub.sla * 24 * 60 * 60 * 1000) - currentTime, 0)
                this.updateRemaining(distance)
                if (distance === 0) {
                    clearInterval(this.timer)
                    this.isEnded = true
                }
            } else {
                clearInterval(this.timer)
            }
        }
    },
    mounted() {
        this.tick()
        this.timer = setInterval(this.tick.bind(this), 1000)
    }
}
</script>

<style lang="scss">
.pgi-box-timers {
    display: flex;

    .timer-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 5px;

        .timer-item {
            width: 35px;
            height: 35px;
            background-color: black;
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            border-radius: 8px;
            box-shadow: 0 0 2px 0 #545454;
        }

        .timer-satuan {
            font-size: 10px;
            font-weight: 600;
        }
    }

}

.video-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 6px;
    overflow: hidden;

    video {
        object-fit: contain;
        border: none;
        width: 100%;
        height: 100%;
    }
}

.item-petugas {
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 0 2px rgba($color: #000000, $alpha: .2);
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px;

    .foto-petugas-img {
        width: 60px;
        height: 60px;
        background-color: #FFF;
        border: 1px solid #DDD;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 5px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .info-petugas {
        .name {
            font-weight: 600;
        }

        .hp {
            font-size: 12px;
        }
    }
}

@media (max-width:1024px) {


    .my-custom-table tbody th,
    .my-custom-table tbody td {
        position: relative;
    }

    .my-custom-table th,
    .my-custom-table td {
        padding: 0.6rem;
        vertical-align: middle;
    }

    /* Force table to not be like tables anymore */
    .my-custom-table thead,
    .my-custom-table tbody,
    .my-custom-table th,
    .my-custom-table td,
    .my-custom-table tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .my-custom-table thead tr,
    .my-custom-table tfoot tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .my-custom-table td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50% !important;
        text-align: left;
    }

    .my-custom-table td:before {
        position: absolute;
        top: 50%;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        left: 25%;
        margin: 0;
        margin: 0;
        transform: translate(-50%, -50%);
    }

    .my-custom-table td:nth-child(1) {
        height: 100%;
        top: 0;
        left: 0;
        font-weight: bold;
        text-align: left;
        background-color: #304f7b;
        color: white;
    }

    .table-history td:nth-of-type(1):before {
        content: "Tanggal";
    }

    .table-history td:nth-of-type(2):before {
        content: "Status";
    }

    .table-history td:nth-of-type(3):before {
        content: "Waktu Proses";
    }

    .table-history td:nth-of-type(4):before {
        content: "Keterangan";
    }

    .table-history td:nth-of-type(5):before {
        content: "Catatan";
    }
}
</style>