<template>
    <div>
        <div v-if="flag === 0">
            <span class="badge badge-custom badge-warning">Ditinjau Ulang</span>
        </div>

        <div v-else-if="flag === 1">
            <span class="badge badge-custom badge-primary">Pengajuan Disetujui (1/2)</span>
        </div>

        <div v-else-if="flag === 2">
            <span class="badge badge-custom badge-primary text-white">Pengajuan Disetujui (2/2)</span>
        </div>

        <div v-else-if="flag === 3">
            <span class="badge badge-custom badge-info text-white">Pengajuan Diproses</span>
        </div>

        <div v-else-if="flag === 4">
            <span class="badge badge-custom badge-success text-white">Pengajuan selesai</span>
        </div>

        <div v-else-if="flag === 8">
            <span class="badge badge-custom badge-secondary">Pengajuan Dibatalkan</span>
        </div>

        <div v-else-if="flag === 10">
            <span class="badge badge-custom badge-dark">Pengajuan Ditolak</span>
        </div>

        <div v-else-if="flag === 11">
            <span class="badge badge-custom badge-danger">Dijadwalkan Ulang</span>
        </div>

        <div v-else-if="flag === 12">
            <span class="badge badge-custom badge-success">Petugas Hadir ({{ row.item.total_maintenance }}/{{
                row.item.count_attendance_maintenance
            }})
            </span>
        </div>
        <div v-else-if="flag === 13">
            <span class="badge badge-custom badge-success">Menunggu Penilaian</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ["flag"]
}
</script>

<style>
.badge-custom {
    font-size: 10px;
}
</style>