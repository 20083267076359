<template>
    <div v-if="memo">
        <b-form-group label="Petugas" class="required" v-if="memo.vendor_type === 0">
            <multiselect :loading="loading" :disabled="loading" :option-height="104" placeholder="Pilih Petugas"
                label="nama" track-by="id" v-model="maintentance" :taggable="false" :show-labels="false"
                :close-on-select="false" :clear-on-select="false" :preserve-search="true" :multiple="true"
                :options="maintenance_options" @remove="handleRemove" @select="checkValueUserMaintenance">
                <template slot="option" slot-scope="props">
                    <div class="item-option-custom">
                        <img @error="handleImageError" class="option__image" :src="props.option.foto" alt="">
                        <div class="option__desc">
                            <span class="option__nama">{{ props.option.nama }}</span>
                        </div>
                    </div>
                </template>
            </multiselect>
        </b-form-group>
        <b-form-group label="Nama Vendor" class="required" v-if="memo.vendor_type === 1">
            <b-form-input v-model="vendor.vendor_name"></b-form-input>
        </b-form-group>
        <b-form-group id="fg-date-maintenance" label="Tanggal Bertugas" label-for="date-maintenance" class="required">
            <date-picker v-model="date" :config="config"></date-picker>
        </b-form-group>
        <div class="d-flex justify-content-end">
            <b-button :disabled="loading" @click="submitPetugasBaru" variant="pgiBtn">
                <i v-if="loading" class="fa fa-spinner fa-spin"></i>
                Simpan
            </b-button>
        </div>

    </div>
</template>

<script>
import axiosClient from '../../../config/api';

export default {
    props: ["maintenance_options", "memo"],
    data() {
        return {
            loading: false,
            date: this.memo.vendor_type == 0 ? new Date(this.memo.memo_maintenance.length ? this.memo.memo_maintenance[0].date : null) : new Date(this.memo.internal_memo_vendor.date),
            maintentance: this.memo ? this.memo.user_maintenance : null,
            vendor: this.memo.internal_memo_vendor,
            config: {
                format: "D MMM YYYY",
                useCurrent: true,
                minDate: null
            },
            formDeleteUser: null
        }
    },
    methods: {
        handleImageError(e) {
            e.target.src = "/img/brand/user.png"
        },
        async handleRemove(value) {
            const idUser = value.id;
            const formDeleteUser = {
                id_memo: [this.memo.id],
                id_user_maintenance: [idUser],
            }
            try {
                this.loading = true
                await axiosClient({
                    method: 'POST',
                    url: 'internal-memo/memo/deleteUserMaintenance',
                    data: formDeleteUser
                });
                this.$helper.toastSucc(this, "Berhasil Merubah petugas")
            } catch (error) {
                this.$helper.toastErr(this, "Gagal Merubah petugas")
            } finally {
                this.loading = false
            }
        },
        async checkValueUserMaintenance() {

        },
        async submitPetugasBaru() {
            if (this.memo.vendor_type === 0) {
                this.submitPetugasInternal()
            } else {
                this.submitPetugasVendor()
            }
        },

        async submitPetugasVendor() {
            const date = this.date;
            const newForm = {
                date: this.$moment(date).format("YYYY-MM-D"),
                id: this.memo.id,
                vendor_name: this.vendor.vendor_name
            }
            try {
                this.loading = true
                const res = await axiosClient({
                    method: 'POST',
                    url: 'internal-memo/memo/update-vendor',
                    data: newForm
                });
                if (res.status === 200) {
                    this.$helper.toastSucc(this, "Berhasil Merubah petugas")
                }
            } catch (error) {
                this.$helper.toastErr(this, "Gagal Merubah petugas")

            } finally {
                this.$bvModal.hide("m-edit-petugas")
                this.loading = true
            }
        },

        async submitPetugasInternal() {
            const petugas = this.maintentance.map(item => item.id)
            const date = this.date;
            const newForm = {
                id_memo: [this.memo.id],
                id_user_maintenance: petugas,
                date: this.$moment(date).format("YYYY-MM-D")
            }
            try {
                this.loading = true
                const res = await axiosClient({
                    method: 'POST',
                    url: 'internal-memo/memo/createUserMaitenance',
                    data: newForm
                });
                if (res.status === 200) {
                    this.$helper.toastSucc(this, "Berhasil Merubah petugas")
                }
            } catch (error) {
                this.$helper.toastErr(this, "Gagal Merubah petugas")

            } finally {
                this.$bvModal.hide("m-edit-petugas")
                this.loading = true
            }
        }
    }
}
</script>

<style lang="scss">
.item-option-custom {
    display: flex;
    padding: 5px;

    .option__image {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    .option__desc {
        .option__nama {
            flex: 16;
        }
    }
}
</style>