<template>
    <div>
        <PageLoader v-if="isLoading" />
        <template v-if="hasFilter && history_fpp.total > 0">
            <div class="d-flex justify-content-end mb-2">
                <b-button @click="downloadExcel" variant="pgiBtn">
                    <i class="fa fa-file-excel-o"></i>
                    Download
                </b-button>
            </div>
            <b-table show-empty small responsive bordered hover :items="history_fpp.rows" :fields="fields"
                class="mb-3 pgi-cutom-table table-fpp">
                <template #empty="scope">
                    <div class="text-center">
                        Data tidak ditemukan
                    </div>
                </template>
                <template v-slot:cell(cabang)="row">
                    <div>{{ row.item.cabang }}</div>
                    <small>{{ row.item.kabkot }}</small>
                </template>
                <template v-slot:cell(kacab)="row">
                    <div>
                        {{ row.item.kepala_cabang ? row.item.kepala_cabang.name : "-" }}
                    </div>
                    <small>AM: {{ row.item.area_manager ? row.item.area_manager.name : "-" }}</small>
                </template>
                <template v-slot:cell(im_number)="row">
                    <div>{{ row.item.im_number }}</div>
                    <small>{{ row.item.created_at | moment("D MMM Y") }}</small>
                </template>
                <template v-slot:cell(kategori_sub)="row">
                    <div>
                        <small>
                            <b>
                                {{ row.item.kategori_jenis }}
                            </b>
                        </small>
                        <span class="d-block">
                            {{ row.item.kategori_sub }}
                        </span>
                    </div>
                </template>
                <template v-slot:cell(status)="row">
                    <BadgeStatus :flag="row.item.flag" />
                </template>
                <template v-slot:cell(keterangan)="row">
                    <small>
                        <b>
                            {{ row.item.catatan_global ? row.item.catatan_global.keterangan : row.item.keterangan }}
                        </b>
                    </small>
                </template>
                <template v-slot:cell(aksi)="row">
                    <template
                        v-if="(row.item.flag == 3 || row.item.flag == 11) && (role === 'General Affair' || role === 'GM General Affair')">
                        <b-dropdown id="dropdown-right" size="sm" right text="Right align" variant="pgiBtn" class="m-0">
                            <template slot="button-content">
                                <i class="py-0 fa fa-bars"></i>
                            </template>
                            <b-dropdown-item href="#" @click="showModalDetail(`${row.item.id}`)">Detail</b-dropdown-item>
                            <b-dropdown-item href="#"
                                @click="showModalUpdateUserMaintenance(row.item.id, { data: row.item })">
                                Ubah Petugas & Tanggal
                            </b-dropdown-item>
                            <b-dropdown-item href="#" @click="showModalUpdateMaterial(`${row.item.id}`)">
                                Ubah Material/Bahan
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <template v-else>
                        <b-button size="sm" @click="showModalDetail(`${row.item.id}`)" variant="pgiBtn">
                            <i class="fa fa-info-circle"></i>
                        </b-button>
                    </template>
                </template>
            </b-table>
            <div class="d-md-flex justify-content-center justify-content-md-between">
                <div class="page-size-container mb-1">
                    <div class="page-size-text">Tampilkan</div>
                    <select @change="changePerPage($event)" v-model="queryParam.per_page" class="form-per-page"
                        id="inputGroupSelect01">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                        <option :value="40">40</option>
                        <option :value="50">50</option>
                    </select>
                    <div class="page-size-text">Baris</div>
                    <div class="ml-2">
                        <span>{{ history_fpp.from }}</span>
                        <span>-</span>
                        <span>{{ history_fpp.to }}</span>
                        <span class="mx-1">dari</span>
                        <span>{{ history_fpp.total }}</span>
                    </div>
                </div>
                <b-pagination class="mb-0" v-model="queryParam.page" :total-rows="history_fpp.total"
                    :per-page="history_fpp.per_page" aria-controls="my-table" @change="changePerPage"></b-pagination>
            </div>
        </template>
        <template v-else>
            <div class="text-center text-danger" v-if="hasFilter">
                Data Tidak ditemukan
            </div>
            <div class="text-center" v-else>
                <span>
                    Silahkan lakukan filter untuk menampilkan data arsip
                </span>
            </div>
        </template>
        <!-- MODAL DETAIL -->
        <b-modal id="m-detail-arsip-fpp" size="xl" title="Detail Internal Memo" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <ModalDetal :memo="detailMemo" />
        </b-modal>
        <!-- MODAL DETAIL -->

        <!-- MODAL EDIT MAINTENANCE -->
        <b-modal centered id="m-edit-petugas" size="md" title="Ubah Tanggal Dan Petugas" no-close-on-backdrop
            no-close-on-esc hide-footer>
            <ModalEditPetugas :maintenance_options="user_maintenance" :memo="detailMemo" />
        </b-modal>
        <!-- MODAL EDIT MAINTENANCE -->

        <!-- MODAL EDIT MAINTENANCE -->
        <b-modal centered id="m-edit-material" size="xl" title="Ubah Material" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <ModalEditMaterial :memo="detailMemo" />
        </b-modal>
        <!-- MODAL EDIT MAINTENANCE -->
    </div>
</template>

<script>
import { mapState } from "vuex"
import BadgeStatus from "./badgeStatus.vue"

import ModalDetal from "./modalDetail.vue"
import ModalEditPetugas from "./modalEditPetugas.vue"
import ModalEditMaterial from "./modalEditBarang.vue"
import PageLoader from "../../pageLoader.vue"
import axiosClient from "../../../config/api"
export default {
    props: ["queryParam", 'hasFilter'],
    components: {
        BadgeStatus,
        ModalDetal,
        ModalEditPetugas,
        ModalEditMaterial,
        PageLoader
    },
    data() {
        return {
            fields: [
                {
                    key: "im_number",
                    label: "Pengajuan",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                {
                    key: "cabang",
                    label: "Cabang",
                    thClass: `nameOfTheClassTh`,
                    tdClass: `nameOfTheClassTd`,
                    sortable: true,
                },
                {
                    key: "kacab",
                    label: "KC/AM",
                    thClass: `nameOfTheClassTh`,
                    tdClass: `nameOfTheClassTd`,
                    sortable: true,
                },
                {
                    key: "kategori_sub",
                    label: "Pengajuan",
                    thClass: "nameOfTheClassTh",
                    sortable: true,
                },
                {
                    key: "status",
                    label: "Status",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                {
                    key: "keterangan",
                    label: "Keterangan",
                    thClass: "max-w-25",
                    sortable: true,
                },
                {
                    key: "aksi",
                    label: "Aksi",
                    thClass: "nameOfTheClassThAction",
                    tdClass: "nameOfTheClassTd",
                },
            ],
            detailMemo: null,
            role: localStorage.getItem("role")
        }
    },
    methods: {
        changePerPage(value) {
            const q = {
                ...this.queryParam,
                page: value,
            }
            this.queryParam = q
            this.$store.dispatch("getHistoryFpp", this.queryParam)
        },

        async showModalDetail(data) {
            const d = await this.$store.dispatch("getDetailMemo", data)
            this.detailMemo = d;
            this.$bvModal.show("m-detail-arsip-fpp")
        },
        async showModalUpdateUserMaintenance(data) {
            const d = await this.$store.dispatch("getDetailMemo", data)
            this.detailMemo = d;
            this.$store.dispatch("getDetailMemo", data)
            this.$bvModal.show("m-edit-petugas")
        },
        async showModalUpdateMaterial(data) {
            const d = await this.$store.dispatch("getDetailMemo", data)
            this.detailMemo = d;
            this.$store.dispatch("getDetailMemo", data)
            this.$bvModal.show("m-edit-material")
        },
        async downloadExcel() {
            const { per_page, page, id_cabang_multiple, ...others
            } = this.queryParam;
            console.log(per_page, page)

            const qDwonload = id_cabang_multiple ? {
                id_cabang_multiple,
                ...others
            } : {
                ...others
            }
            try {
                const response = await axiosClient({
                    url: `internal-memo/memo/laporan/perbaikan-by-filter`,
                    method: 'GET',
                    params: qDwonload,
                    responseType: 'blob',
                })
                if (response.status === 200) {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `laporan-fpp-${new Date().getTime()}.xlsx`
                    )
                    document.body.appendChild(link)
                    link.click()
                }
            } catch (error) {
                this.$helper.toastErr(this, "Gagal download")
            }
        }
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        history_fpp() {
            return this.$store.state.fpp.history_fpp
        },
        filtered_cities() {
            return this.$store.state.wilayah.cities
        },
        kategori_sub() {
            return this.$store.state.fpp.kategori_sub
        },
        kategori_jenis() {
            return this.$store.state.fpp.kategori_jenis
        },
        kategori_im() {
            return this.$store.state.fpp.kategori_im
        },
        option_status_fpp() {
            return this.$store.state.fpp.option_status_fpp
        },
        cabangLists() {
            return this.$store.state.global_data.cabangLists
        },
        user_maintenance() {
            return this.$store.state.fpp.user_maintenance
        },
    }
}
</script>

<style lang="scss">
.max-w-25 {
    width: 150px;
}
</style>